@import '../../../styles/variables';

header {
	top: 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-container {
	position: sticky;
	top: 0;
	background-color: $color-background;
	z-index: 10;
}

@media only screen and (max-width: 650px) {
	header {
		flex-direction: column;
	}

	.header-logo {
		margin-bottom: $spacing-sm;
	}
}
