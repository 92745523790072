.social-tab {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.tab-row {
	flex-direction: row;
}
