.skills-header {
	display: flex;
	align-items: center;

	cursor: pointer;
}

.code-icon,
.expand-icon {
	width: 3.5rem;
}

.expand-less {
	transform: rotate(180deg);
}
