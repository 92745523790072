@import '../../../styles/variables';

.hero-title {
	font-size: $font-size-xxl;
	color: $color-black;
}

.hero-subtitle {
	font-size: $font-size-md;
	font-weight: $font-weight-med;
}

.title {
	font-size: $font-size-xl;
	font-weight: $font-weight-semi-bold;
	text-align: center;
	color: $color-black;
}

.subtitle {
	font-size: $font-size-sm;
	font-weight: $font-weight-reg;
	text-align: center;
}

.heading {
	font-size: $font-size-md;
	font-weight: $font-weight-semi-bold;
	color: $color-black;
}

.stats {
	font-size: $font-size-lg;
	font-weight: $font-weight-semi-bold;
	color: $color-black;
}

.stat-text {
	font-size: $font-size-sm;
}

.skill-text {
	font-size: $font-size-body;
	font-weight: $font-weight-med;
	color: $color-black;
}

.footer-title {
	font-size: $font-size-xl;
	color: $color-white;
	line-height: 1;
}

.footer-subtitle {
	font-size: $font-size-sm;
	font-weight: $font-weight-reg;
	color: $color-white;
}
