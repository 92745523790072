@import '../../../styles/variables';

footer {
	background-color: $color-primary;
}

.footer-content {
	width: 60%;
	margin: 0 auto;
}

.footer-nav > ul > a > li {
	color: $color-white;

	&:hover {
		color: $color-primary-light;
	}
}

@media only screen and (max-width: 650px) {
	.left,
	.footer-nav {
		margin: 0 auto;
		text-align: center;
	}
}
