.hero-main {
	display: flex;
}

.hero-img {
	width: 100%;
	align-self: center;
}

@media only screen and (max-width: 550px) {
	.hero-left {
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.hero-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
