/***** COLOR *****/
$color-primary-dark: #205a5a;
$color-primary: #297373;
$color-primary-light: #369696;
$color-text: #2b0504;
$color-text-light: #a19fad;
$color-background: #f2efea;

$color-black: #000000;
$color-white: #f2efea;

/***** FONT FACE *****/
$font-family-primary: 'Poppins', sans-serif;

/***** FONT SIZE *****/
$font-html-default: 62.5%;

$font-size-sm: 1.4rem;
$font-size-body: 1.6rem;
$font-size-md: 2rem;
$font-size-lg: 2.6rem;
$font-size-xl: 3.6rem;
$font-size-xxl: 4.8rem;

/***** FONT WEIGHT *****/
$font-weight-reg: 400;
$font-weight-med: 500;
$font-weight-semi-bold: 600;

// ***** SPACING *****/

$spacing-xxs: 1rem;
$spacing-xs: 1.2rem;
$spacing-sm: 2rem;
$spacing-md: 3.2rem;
$spacing-lg: 6.4rem;
$spacing-xxl: 10rem;
