@import '../../../styles/_variables.scss';

.header-logo,
.nav-item,
.footer-nav-item {
	font-weight: 500;
	color: $color-black;
	text-decoration: none;

	&:hover {
		color: $color-primary;
	}

	&:active {
		color: $color-primary-dark;
	}
}

.header-logo {
	font-size: $font-size-md;
}

.nav-item,
.footer-nav-item {
	list-style: none;
}

.nav-item {
	margin-left: 3.2rem;
}

.footer-nav-item:not(:first-of-type) {
	margin-left: 3.2rem;
}

.nav-active {
	color: $color-primary;

	&:hover,
	&:active {
		color: $color-primary-dark;
	}
}
