@import '../../../styles/variables';
.carousel-img-container {
	display: flex;
}

.carousel-img {
	width: 70%;
	margin: 0 0 0 auto;
	border-radius: 0.75rem;
}

.carousel-text {
	width: 70%;
}

.btn-group {
	display: flex;
}

.pagination {
	text-align: center;
	margin-top: $spacing-md;
}

.swiper-container {
	width: 480px;
}

.swiper-pagination-bullet-active {
	background-color: $color-primary-dark;
}

.swiper-button-prev,
.swiper-button-next {
	color: $color-primary-dark;
}

.swiper-slide-active {
	margin-right: 0 !important;
	padding: 0;
}

@media only screen and (max-width: 1000px) {
	.carousel-img {
		width: 50%;
		margin: 0 auto;
	}
	.carousel-text {
		width: 100%;
	}
}

@media only screen and (max-width: 650px) {
	.carousel-img {
		width: 80%;
		margin: 0 auto;
	}
}
