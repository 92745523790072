@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://unpkg.com/swiper@7/swiper-bundle.min.css');
@import './styles/variables';

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
  user-select: none;
}

html {
  box-sizing: border-box;
  font-size: $font-html-default;
}

body {
  font-family: $font-family-primary;
  font-size: $font-size-body;
  font-weight: $font-weight-reg;
  color: $color-text;
  background-color: $color-background;
}

header,
section {
  width: 80%;
  margin: 0 auto;
}

#home,
#about,
#skills,
#projects {
  padding-top: 18rem;
  margin-top: -18rem;
}

.icon-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(168deg)
    brightness(200%) contrast(101%);
}

.icon-rotate-90 {
  transform: rotate(270deg);
}

.icon-rotate-180 {
  transform: rotate(180deg);
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 50%;
  }
}
