.skills-dropdown {
	display: none;
}

.skill-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.skill-icon {
	width: 3.5rem;
}

.skills-active {
	display: block;
}
