@import '../../../styles/variables';

.top {
	&-xs {
		padding-top: $spacing-xs;
	}

	&-sm {
		padding-top: $spacing-sm;
	}

	&-md {
		padding-top: $spacing-md;
	}

	&-lg {
		padding-top: $spacing-lg;
	}

	&-xxl {
		padding-top: $spacing-xxl;
	}
}

.right {
	&-xs {
		padding-right: $spacing-xs;
	}

	&-sm {
		padding-right: $spacing-sm;
	}

	&-md {
		padding-right: $spacing-md;
	}

	&-lg {
		padding-right: $spacing-lg;
	}

	&-xxl {
		padding-right: $spacing-xxl;
	}
}

.btm {
	&-xs {
		padding-bottom: $spacing-xs;
	}

	&-sm {
		padding-bottom: $spacing-sm;
	}

	&-md {
		padding-bottom: $spacing-md;
	}

	&-lg {
		padding-bottom: $spacing-lg;
	}

	&-xxl {
		padding-bottom: $spacing-xxl;
	}
}

.left {
	&-xs {
		padding-left: $spacing-xs;
	}

	&-sm {
		padding-left: $spacing-sm;
	}

	&-md {
		padding-left: $spacing-md;
	}

	&-lg {
		padding-left: $spacing-lg;
	}

	&-xxl {
		padding-left: $spacing-xxl;
	}
}

@media only screen and (max-width: 800px) {
	.top {
		&-xxl {
			padding-top: $spacing-md;
		}
	}

	.btm {
		&-lg {
			padding-bottom: $spacing-sm;
		}
		&-xxl {
			padding-bottom: $spacing-md;
		}
	}
}
