@import '../../../styles/variables';

.btn {
	&-primary {
		display: inline-flex;
		align-items: center;
		background-color: $color-primary;
		color: #fff;
		padding: 1.6rem;
		border: none;
		border-radius: 0.8rem;
		font-family: $font-family-primary;
		font-size: $font-size-body;
		font-weight: $font-weight-med;
		text-decoration: none;
		cursor: pointer;

		&:hover {
			transform: scale(1.05);
			box-shadow: 3px 7px 20px 1px rgba(0, 0, 0, 0.2);
		}

		&:active {
			box-shadow: unset;
			transform: none;
		}
	}

	&-icon {
		width: 2.5rem;
		margin-left: 1rem;
	}

	&-centered {
		margin: 0 auto;
	}
}
